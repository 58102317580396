import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import "./RollerLoading.scss";
// Loading indicator
class Loading extends React.Component {
    render() {
        if (this.props.cnt === 0) {
            // this is no on-going loading, disable loading indicator
            return "";
        }
        const loadingPanel = (
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        );
        const gridContent = document.querySelector("#root");
        return ReactDOM.createPortal(loadingPanel, gridContent);
    }
}
function mapStateToProps(state) {
    return {
        cnt: state.rollerLoading // total number of loading in progress
    };
}

export default connect(
    mapStateToProps,
    null
)(Loading);
