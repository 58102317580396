import axios from "axios";
import store from '../../store/store';
import { Action } from '../../actions/ActionType';
import storage from "./storage";

const httpErrors = {
    0: 'Connection',
    1: 'Response not expected',
    2: 'Authentication',
    3: 'Redirectional',
    4: 'Client',
    5: 'Server'
}

let alertTime, sessionTimeout, sessionExpirePopup, interval;
// let logoutEvent;
let apiCallTime, timeoutTime = null;
export let extendSessionUrl = "/secure/api/extendSession/";
let intervalTimeDifference = 2;


export const HttpMethod = {
    POST: 'post',
    GET: 'get'
}



export const makeDownloadRequest = async (requestURL, method, body, loading = true) => {
    const response = await sendRequest(requestURL, method, body, 'blob', loading);

    if (response) {
        let fileName = "default.txt";
        if (response.headers['content-disposition']) {
            fileName = response.headers['content-disposition'].split('filename=')[1];
        }
        return {
            success: true,
            data: response.data,
            filename: fileName
        };
    }
}

export const printDraft = async (requestURL, method, body, loading = true) => {
    const response = await sendRequest(requestURL, method, body, 'blob', loading);

    if (response) {
        return {
            success: true,
            data: response.data
        };
    }
}


export const makeRequest = async (requestURL, method = "get", data, requestType = "json", loading = true, responseErrorHandle) => { //NOSONAR
    storage.set("apiCallTime", new Date(), false);
    handleSessionTimeout();
    const response = await sendRequest(requestURL, method, data, requestType, loading);
    if (response) {
        const { data } = response; //NOSONAR
        //    let process = true;
        if (data && !data.success != undefined && !data.success) { // eslint-disable-line
            if (typeof responseErrorHandle === 'function') {
                responseErrorHandle(data);
            } else {
                if (typeof data.error === 'string') {
                    if (data.error.includes("<br/>")) {
                        data.error = data.error.replace(/<br\/>/g, "\n");
                    }
                    if (data.error === "No Shipping Instruction data received" || data.error === "No Carrier Booking data created." || data.error === "Shipping instructions not found for the case.") {
                        return data;
                    }
                    showErrorDialog(data.error)
                }
            }
        } else if (data && !data.success != undefined && data.success && data.error) { // eslint-disable-line
            if (typeof data.error === 'string') {
                if (data.error.includes("<br/>")) {
                    data.error = data.error.replace(/<br\/>/g, "\n");
                }
                showErrorDialog(data.error)
            }
        }
        // TODO: stop if process === false
        return data;
    }
}

const sendRequest = async (requestURL, method = "get", data, requestType = "json", loading) => { //NOSONAR
    // if (!logoutEvent) {
    //     logoutEvent = window.addEventListener('storage', e => {

    //         if (e.key === null) {
    //             window.location.href = '/';
    //         }

    //     });
    // }
    if (loading) {
        store.dispatch({
            type: Action.ACTION_LOADING
        });
    }
    axios.defaults.headers.common['APP'] = "TED";

    const response = await axios({
        method: method,
        url: requestURL,
        responseType: requestType,
        data: data
    }).then(res => {
        // TED-5558 fix disabled    	
        //        if (res && res.headers && res.headers.version) {
        //            if (version !== res.headers.version) {  
        //              ReactDOM.render(<VersionPopUp />, document.getElementById("version"));
        //            }
        //          }
        return res;
    }).catch(res => {
        if (res.response !== undefined) {
            //Return back-end handled error.
            if (res.response.statusText === "Unauthorized") {
                sessionExpireDialog()
            } else {
                if (res && res.response && res.response.status === 400 && res.response.request && res.response.request.responseType === 'blob' && res.response.data && res.response.data.type === "application/json") {
                    let b = new Blob([res.response.data], { type: "application/json" });
                    b.text().then(text => {
                        let err = ''
                        let blobData = JSON.parse(text);
                        err = blobData.error;
                        showErrorDialog(err)
                    });
                }
                else {
                    let errorString = "";
                    if (res.response.data) {
                        errorString = res.response.data.error;
                        if (!errorString) {
                            errorString = "No data found!"
                        }
                    }
                    else {
                        const errInt = parseInt(res.response.status / 100);
                        let errMsg = httpErrors[errInt]
                        errorString = errMsg !== undefined ? errMsg : res.message;
                        // errorString = + ' Error';
                        errorString += " Error";
                    }
                    showErrorDialog(errorString);
                }
            }
        } else {
            //Unhandled error.   
            // When error status code is 403 because of the session expiry on the backend.         
            if (res.response === undefined && storage.get("userName"))
                sessionExpireDialog()
            throw res;
        }
        // throw err for next handle
        throw res;
    }).finally(() => {
        if (loading) {
            store.dispatch({
                type: Action.ACTION_LOADED
            });
        }
    });
    return response;
}

function checkForTimeouts(warningTime) {
    if (interval) {
        clearInterval(interval);
    }
    alertTime = storage.get("alertTime")
    timeoutTime = storage.get("timeoutTime");
    apiCallTime = storage.get("apiCallTime");
    interval = setInterval(function () {
        if (timeoutTime > 0) {
            timeoutTime = timeoutTime - intervalTimeDifference;
        } else {
            let doubleCheckTime = storage.get("apiCallTime");
            if (doubleCheckTime === apiCallTime) {
                clearInterval(interval);
                showWarningDialog(apiCallTime)
            } else {
                //do nothing
            }
        }
    }, intervalTimeDifference * 1000);
}

function handleSessionTimeout() {
    if (storage.get("userName")) {
        clearTimeout(sessionExpirePopup);

        storage.set("timeoutTime", storage.get("sessionTimeout") - storage.get("alertTime"), false);
        storage.set("apiCallTime", new Date(), false);
        checkForTimeouts(sessionTimeout - alertTime);
    }
}

function showWarningDialog(callTime) {
    clearTimeout(sessionExpirePopup);
    sessionExpirePopup = setTimeout(function () {
        let doubleCheckTime = storage.get("apiCallTime");
        if (doubleCheckTime === callTime) {
            sessionExpireDialog()
        } else {
            //do nothing
        }
    }, alertTime * 1000);

    //here we create warning dialog
    store.dispatch({
        type: Action.ACTION_SHOW_WARN,
        msgId: 'SESSION_EXPIRE_WARNING',
        buttons: [{
            text: 'Cancel',
            callback: () => { }
        }, {
            text: 'Continue',
            callback: () => {
                httpGet(extendSessionUrl)
            },
            isPrimary: true
        }]
    });



}

function showErrorDialog(errorString) {
    store.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        msg: errorString
    });
}


function sessionExpireDialog() {
    // alert
    store.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        msgId: 'SESSION_EXPIRED',
        callback: Action.ACTION_USER_LOGOUT_AND_REDIRECT
    });
}

export const httpGet = async (url, reqData, reqType = "json", loading = true, //NOSONAR
    responseErrorHandler) =>
    await makeRequest(url, HttpMethod.GET, reqData, reqType, loading, responseErrorHandler);

export const httpPost = async (url, reqData, reqType = "json", loading = true, //NOSONAR
    responseErrorHandler) =>
    await makeRequest(url, HttpMethod.POST, reqData, reqType, loading, responseErrorHandler);

export const redirectWithCredentials = (host, url) => {
    var client = new XMLHttpRequest();
    client.open("GET", url);
    client.setRequestHeader("Set-Cookie", document.cookie);

    // let urlParams = new URLSearchParams();
    // urlParams.append("userName", userName);
    // window.location.href = host + url+ "?" + urlParams.toString() ;
    storage.remove("apiCallTime")
    window.location.href = host + url;
}    
