export default {
  loading: 0,
  rollerLoading: 0,
  notification: {
    visible: false,
    msgIds: [],
    msgId: '',
    msgParams: {},
    msg: '',
    type: '',
    title: '',
    callback: undefined
  },
  overlay: {
    visible: false,
    item: '',
    wrapperClassName: '',
    args: undefined,
    showButton: false,
    button: undefined,
    onClosing: undefined,
    onClosed: undefined
  },
  confirm: {
    visible: false,
    msg: '',
    msgId: '',
    msgParams: {},
    title: '',
    yes: undefined,
    no: undefined,
    close: undefined
  },
  popup: {
    visible: false,
    data: '',
    title: '',
    className: '',
    width: '',
    callback: ''
  },
  // shippingInstruction: {
  //   masterData: {
  //     countryList: [],
  //     countryRegionsMap: {},
  //     regionPortsMap: {}
  //   }
  // },
  config: {
    container: {
      containerGrid: {
        gridColumns: []
      },
      containerDetailGrid: {
        gridColumns: []
      },
      gridColumnOps: {
        operations: []
      },
      containerTypes: [],
      savedSearch: {},
      timezoneList: [],
      milestoneGrid: {},
      eventList: []
    },
    shippingInstruction: {
      gridColumnOps: {
        operations: []
      },
      codes: [],
      actualCargoGrid: {
        gridColumns: [],
        itemsPerPage: 10,
        totalItems: 100
      },
      timezoneList: [],
      movementTypes: [],
      selectedMenu: "recentSearch",
      siStatuses: [],
      siFieldConfig: "",
      create: true,
      shippingInstructionId: "",
      serviceTypes: [],
      userSearch: null,
      shippingInstructionGrid: {
        gridColumns: [],
        itemsPerPage: 20,
        totalItems: 250
      },
      bookedCargoGrid: {
        gridColumns: [],
        itemsPerPage: 10,
        totalItems: 100
      },
      containerTypes: []
    },
    carrierBooking: {
      gridColumnOps: {
        operations: []
      },
      freightTerms: [],
      blTypes: [],
      ediContainerTypes: [],
      ediMovementTypes: [],
      ediServiceTypes: [],
      timezoneList: [],
      movementTypes: [],
      chargeTerms: [],
      carrierWaybillTypes: [],
      selectedMenu: "recentSearch",
      siStatusList: [],
      siFieldConfig: "",
      create: true,
      shippingInstructionId: "",
      serviceTypes: [],
      userSearch: {
        companyId: null,
        filterConditionAnd: [],
        filterConditionOr: [],
        defaultConditionsAnd: [],
        defaultConditionsOr: [],
        sort: null,
        page: 1,
        pageSize: 250
      },
      carrierBookingGrid: {
        gridColumns: [],
        itemsPerPage: 20,
        totalItems: 250
      },
      bookedCargoGrid: {
        gridColumns: [],
        itemsPerPage: 10,
        totalItems: 100
      },
      carrierTypes: [],
      statusOptions: [],
      searchSIGrid: {
        gridColumns: [],
        itemsPerPage: 20,
        totalItems: 250
      },
      containerTypes: [],
      siPrivilegeBasedOnStatus: "",
      ediStatusOptions:[],
      oceanScheduleGrid:[],
      oceanScheduleDetailsGrid:[],
      scheduleTypeList:[]
    },
    progressOverview: {
      maxHeight: "calc(100vh - 155px)",
      gridColumnOps: {
        operations: []
      },
      OVERVIEW_ImportExportSwitch: "export",
      freightTerms: [],
      blTypes: [],
      timezoneList: [],
      movementTypes: [],
      chargeTerms: [],
      carrierWaybillTypes: [],
      selectedMenu: "recentSearch",
      siStatusList: [],
      siFieldConfig: "",
      create: true,
      shippingInstructionId: "",
      serviceTypes: [],
      userSearch: undefined,
      progressOverviewGrid: {
        gridColumns: [],
        itemsPerPage: 20,
        totalItems: 250
      },
      bookedCargoGrid: {
        gridColumns: [],
        itemsPerPage: 10,
        totalItems: 100
      },
      carrierTypes: [],
      statusOptions: [],
      searchSIGrid: {
        gridColumns: [],
        itemsPerPage: 20,
        totalItems: 250
      },
      containerTypes: [],
      siPrivilegeBasedOnStatus: "",
      busProcessStatuses: []
    }
  },
  header: {
    userName: "Ourkwe",
    terminal: "1500",
    profileData: "",
    menuData: "",
    moduleName: ""
  }
};