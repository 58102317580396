import { Action } from "../actions/ActionType";
// import initialState from "./initialState";
import storage from "../common/services/storage";

export default function notificationUIReducer(
    state = { //NOSONAR
        data: [],
        config: {},
        dataCount: 0,
        unreadNotifications: {
            "siConfirm_info": [],
            "siConfirm_error": [],
            "siUpload_info": [],
            "siUpload_error": []
        },
        notifTimeStamp: ""
    },
    action
) {
    if (action.type) {
        let data, notificationUpdatedData, notificationsLength, unreadNotifications, unreadNotificationsCount, dataCount, notifTimeStamp;
        switch (action.type) {

            case Action.ACTION_SAVE_NOTIFICATION_ICONS:
                data = action.data.data;
                dataCount = data.length;
                notificationUpdatedData = action.data.data;
                notificationsLength = notificationUpdatedData.length;
                unreadNotifications = {};
                unreadNotificationsCount = {};
                unreadNotificationsCount["siConfirm_info"] = 0;
                unreadNotificationsCount["siConfirm_error"] = 0;
                unreadNotificationsCount["siUpload_info"] = 0;
                unreadNotificationsCount["siUpload_error"] = 0;
                if ((notificationsLength > 0)) {

                    let siConfirm_info = notificationUpdatedData.filter(item => item.subType === "CONFIRM" && (item.level === "INFO" || item.severity === "INFO"))
                    let siConfirm_error = notificationUpdatedData.filter(item => item.subType === "CONFIRM" && (item.level === "ERROR" || item.severity === "ERROR"))
                    let siUpload_info = notificationUpdatedData.filter(item => item.subType === "UPLOAD" && (item.level === "INFO" || item.severity === "INFO"))
                    let siUpload_error = notificationUpdatedData.filter(item => item.subType === "UPLOAD" && (item.level === "ERROR" || item.severity === "ERROR"))

                    unreadNotifications["siConfirm_info"] = siConfirm_info;
                    unreadNotifications["siConfirm_error"] = siConfirm_error;
                    unreadNotifications["siUpload_info"] = siUpload_info;
                    unreadNotifications["siUpload_error"] = siUpload_error;

                    unreadNotificationsCount["siConfirm_info"] = siConfirm_info.length;
                    unreadNotificationsCount["siConfirm_error"] = siConfirm_error.length;
                    unreadNotificationsCount["siUpload_info"] = siUpload_info.length;
                    unreadNotificationsCount["siUpload_error"] = siUpload_error.length;
                    // storage.set("notifData",data.length);
                }
                storage.setObject("unreadNotificationsCount", unreadNotificationsCount);
                storage.set("notifData", dataCount);
                return { ...state, data, dataCount, unreadNotifications };

            case Action.ACTION_READ_NOTIFICATIONS:

                data = action.data.data;
                dataCount = data.length;
                unreadNotifications = {};
                unreadNotifications["siConfirm_info"] = [];
                unreadNotifications["siConfirm_error"] = [];
                unreadNotifications["siUpload_info"] = [];
                unreadNotifications["siUpload_error"] = [];

                unreadNotificationsCount = {};
                unreadNotificationsCount["siConfirm_info"] = 0;
                unreadNotificationsCount["siConfirm_error"] = 0;
                unreadNotificationsCount["siUpload_info"] = 0;
                unreadNotificationsCount["siUpload_error"] = 0;
                storage.setObject("unreadNotificationsCount", unreadNotificationsCount);
                return { ...state, data, dataCount, unreadNotifications };

            case Action.ACTION_SAVE_NOTIFICATIONS_COUNT:
                dataCount = action.data.data;
                notifTimeStamp = Date.now();

                return { ...state, dataCount, notifTimeStamp }

            case Action.ACTION_SAVE_NOTIFICATIONS_FROM_SOCKETS:
                data = state.data ? [...state.data, ...action.data.data] : action.data.data;
                dataCount = data.length;
                notifTimeStamp = Date.now();
                notificationUpdatedData = data;
                notificationsLength = notificationUpdatedData.length;
                unreadNotificationsCount = {};
                unreadNotificationsCount["siConfirm_info"] = 0;
                unreadNotificationsCount["siConfirm_error"] = 0;
                unreadNotificationsCount["siUpload_info"] = 0;
                unreadNotificationsCount["siUpload_error"] = 0;
                unreadNotifications = state.unreadNotifications;
                if ((notificationsLength > 0)) {
                    let siConfirm_info = notificationUpdatedData.filter(item => item.subType === "CONFIRM" && (item.level === "INFO" || item.severity === "INFO"))
                    let siConfirm_error = notificationUpdatedData.filter(item => item.subType === "CONFIRM" && (item.level === "ERROR" || item.severity === "ERROR"))
                    let siUpload_info = notificationUpdatedData.filter(item => item.subType === "UPLOAD" && (item.level === "INFO" || item.severity === "INFO"))
                    let siUpload_error = notificationUpdatedData.filter(item => item.subType === "UPLOAD" && (item.level === "ERROR" || item.severity === "ERROR"))
                    if (Array.isArray(unreadNotifications["siConfirm_error"]))
                        unreadNotifications["siConfirm_error"].push(...siConfirm_error);
                    else
                        unreadNotifications["siConfirm_error"] = siConfirm_error

                    if (Array.isArray(unreadNotifications["siConfirm_info"]))
                        unreadNotifications["siConfirm_info"].push(...siConfirm_info);
                    else
                        unreadNotifications["siConfirm_info"] = siConfirm_info;

                    if (Array.isArray(unreadNotifications["siUpload_info"]))
                        unreadNotifications["siUpload_info"].push(...siUpload_info);
                    else
                        unreadNotifications["siUpload_info"] = siUpload_info;

                    if (Array.isArray(unreadNotifications["siUpload_error"]))
                        unreadNotifications["siUpload_error"].push(...siUpload_error);
                    else
                        unreadNotifications["siUpload_error"] = siUpload_error;

                    unreadNotificationsCount["siConfirm_info"] = siConfirm_info.length;
                    unreadNotificationsCount["siConfirm_error"] = siConfirm_error.length;
                    unreadNotificationsCount["siUpload_info"] = siUpload_info.length;
                    unreadNotificationsCount["siUpload_error"] = siUpload_error.length;

                }
                storage.setObject("unreadNotificationsCount", unreadNotificationsCount)


                return { ...state, data, dataCount, unreadNotifications, notifTimeStamp };


            case Action.ACTION_SAVE_NOTIFICATIONS_SETTINGS:
                let payload = {
                    "settings": {
                        "items": action.data[0], "tabRows": action.data[1]
                    }
                }
                // let data = action.data;
                return { ...state, payload };

            case Action.ACTION_SAVE_NOTIFICATIONS_CONFIG:
                let config = action.data.data;
                return { ...state, config };

            case Action.ACTION_CLEAR_NOTIFICATIONS:
                data = [];
                dataCount = 0;
                unreadNotifications = {
                    "siConfirm_info": [],
                    "siConfirm_error": [],
                    "siUpload_info": [],
                    "siUpload_error": []
                }
                return { ...state, data, dataCount, unreadNotifications };

            case Action.ACTION_UPDATE_ICON_TYPE:
                const iconType = action.iconType ? action.iconType : 'default';
                return { ...state, iconType };
            case Action.ACTION_DATA_REFRESH_TRUE: 
               return {...state,reload: true}
            case Action.ACTION_DATA_REFRESH_FALSE: 
               return {...state,reload: false}

            default:
                return state;
        }
    }
    else
        return state;
}
