import React, { Component } from "react";
import Fallback from "../Common/Fallback";
import ProtectedRoute from "../../protectedRoute";
import { Switch } from "react-router-dom";
import Login from "../Login/CSSLoginUpdated";

class Routes extends Component {
    render() {
        return (
            <Switch>
                <ProtectedRoute path="/secure/logout/" exact component={Login} />
                <ProtectedRoute path="*" component={Fallback} />
            </Switch>
        );
    }
}

export default Routes;
