import initialState from "./initialState";
import { Action } from "../actions/ActionType";

export default function headerReducer(state = initialState.header, action) { //NOSONAR
  switch (action.type) {
    case "UPDATE_PROFILE_DATA":
      return {
        profileData: action.profileData,
        menuData: action.menuData
      };

    case Action.ACTION_FILE_UPLOAD:
      let uploaded = true;
      return {
        ...state,
        uploaded
      }

    case "ACTION_UPDATE_MODULE_NAME":
      const moduleName = action.moduleName ? action.moduleName : '';
      return {
        ...state,
        moduleName
      }

    default:
      return state;
  }
}
