import React, { Component } from "react";
import { connect } from "react-redux";
import storage from "../../common/services/storage";
import { Action } from "../../actions/ActionType";
import NotificationDialog from "../../components/Common/NotificationDialog";
import Loading from "../../components/Common/Loading";
import { loadMsgToStorage } from "../../common/services/commonService";
import axios from "axios";
import "../../styles/App.scss";
import "./cssLogin.scss";
import "../../styles/bootstrap.min.css";
import Footer from "../Layout/Footer";
import store from "../../store/store";
import notificationUI from "../../reducers/notificationUIReducer";
import ufs_logo from "../../assets/logos/CSS.png";
import underline from "../../assets/images/underline.png";
import bigger_underline from "../../assets/logos/longer-line.png";
import Slideshow1 from "../../assets/images/banner_images/banner_image1.png";
import Slideshow2 from "../../assets/images/banner_images/banner_image2.png";
import Slideshow3 from "../../assets/images/banner_images/banner_image3.png";
import Slideshow4 from "../../assets/images/banner_images/banner_image4.png";
import kwe_logo from "../../assets/images/kwe_logo1.png";
import { redirectWithCredentials } from "../../common/services/ajaxService";
import { Link } from "react-router-dom";
// import * as actions from "../../actions/NotificationsAction";
const httpErrors = {
  0: 'Connection',
  1: 'Response not expected',
  2: 'Authentication',
  3: 'Redirectional',
  4: 'Client',
  5: 'Server'
}

const LANDING_PAGE_URL = '/ui/landing/';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hideLogin: false,
      error: false,
      errorMessage: "",
      profileData: {},
      menuData: {},
      localMessages: {
        NO_ROLES_ASSIGNED: "Invalid User ID.",
        LOGIN_ERR: "Login Error",
        SOMETHING_WENT_WRONG: "Something went wrong. Please try again later.",
        SERVER_ERR: "Server Error",
      },
      shipmentNumbertype: 'customerWaybill',
      shipmentNumber: '',
    };
  }
  // Move these var / const to Global Props later
  serverContextUrl = ""; //"http://localhost:8080";
  urlLogin = "/b2c/signin/";
  urlSignup = "/b2c/signup/";
  componentDidMount() {
    store.injectReducer("notificationUI", notificationUI);
    const { loggingout } = this.props.location.state || {};
    const { pathname } = this.props.location || "";
    /* pathname === '/secure/logout/' : Handle logout from UDS/ ShipmentTrace site */
    if (loggingout || pathname === "/secure/logout/") {
      this.props.dispatch({
        type: Action.ACTION_USER_LOGOUT,
        history: this.props.history,
      });
      //  loggingout=false;
    } else {
      let queryString = window.location.search;
      const urlParams = queryString ? new URLSearchParams(queryString) : null;

      //******** If any error during login or new signup in process then redirect to relevant page  *************/
      if (urlParams && urlParams.get("page")) {
        let path = urlParams.get("page");
        this.props.history.push(path);
      }
      //******** In case of unsuccessful login show error popup based on errorKey *************/
      else if (urlParams && urlParams.get("errorKey")) {
        let errorKey = urlParams.get("errorKey");
        let errorMessage = errorKey === "NO_ROLES" ? this.state.localMessages.NO_ROLES_ASSIGNED : this.state.localMessages.SOMETHING_WENT_WRONG;
        store.dispatch({
          type: Action.ACTION_SHOW_ERROR,
          msg: errorMessage,
          callback: () => {
            this.props.history.push("/");
          },
        });
      }
      //******** If the user has successfully logged-in then redirect to Landing Page *************/
      else if (storage.getCookie("ted_signed_id") !== undefined && storage.getCookie("ted_signed_id") !== null) {
        this.handleRedirection();
      }
    }
  }

  handleRedirection = () => {
    this.setState({ loading: true, hideLogin: true }, () => {
      this.getProfileData().then((res) => {
        // actions.getNotificationData();
        if (res && res.data && res.data.data) {  // If the cookie is invalid then res.data.data will be null
          const { data } = res.data;

          storage.set("userName", data.login.name, false);
          storage.set("alertTime", data.alertTime, false);
          storage.set("sessionTimeout", data.sessionTimeout, false);
          storage.setObject("profileData", data, false);
          // Call API to load message to storage
          loadMsgToStorage();

          this.props.dispatch({
            type: "UPDATE_PROFILE_DATA",
            profileData: {
              data: data,
            },
            menuData: {
              data: data,
            },
          });
          this.setState({
            loading: false,
            // hideLogin: false,
          });
          //***** If the login is successful but topLevelMenuList is empty then redirect to signup processing page ********/
          let redirectionURL = '/?page=/signupprocessing';
          if (Array.isArray(data.topLevelMenuList) && data.topLevelMenuList.length > 0) {
            redirectionURL = LANDING_PAGE_URL;
          }

          const baseurl = window.location.origin;
          redirectWithCredentials(baseurl, redirectionURL);
        } else {
          this.setState({
            loading: false,
            hideLogin: false,
          });
        }
      }).catch(err => {
        console.log(err)
      });
    });
  };

  async getProfileData() {
    let menuDataResponse = await axios
      .get("/secure/landing/menuprofile/", { headers: { APP: "TED" } })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        this.setState({
          loading: false,
        });
        if (res.response !== undefined) {
          //Return back-end handled error.
          if (res.response.statusText === "Unauthorized" || res.response.status === 401) {
            store.dispatch({
              type: Action.ACTION_SHOW_ERROR,
              msgId: 'SESSION_EXPIRED',
              callback: Action.ACTION_USER_LOGOUT_AND_REDIRECT
            });
            throw res
          } else {
            let errorString = "";
            if (res.response.data) {
              errorString = res.response.data.error;
              if (!errorString) {
                errorString = "No data found!"
              }
            }
            else {
              const errInt = parseInt(res.response.status / 100);
              let errMsg = httpErrors[errInt]
              errorString = errMsg !== undefined ? errMsg : res.message;
              // errorString = + ' Error';
              errorString += " Error";
            }
            store.dispatch({
              type: Action.ACTION_SHOW_ERROR,
              msg: errorString
            });
          }
        } else {
          if (res.response === undefined)
            store.dispatch({
              type: Action.ACTION_SHOW_ERROR,
              msgId: 'SESSION_EXPIRED',
              callback: Action.ACTION_USER_LOGOUT_AND_REDIRECT
            });
          throw res
        }
      });
    return {
      ...menuDataResponse,
    };
  }

  signupClick = () => {
    this.setState({ loading: true, hideLogin: false }, () => {

      const requestBody = {
        userId: ""
      };
      const requestheader = {
        APP: "TED"
      };
      axios.post(
        this.serverContextUrl + this.urlSignup,
        requestBody,
        requestheader
      ).then(res => {
        if (res.data && res.data.success) {
          redirectWithCredentials(res.data.data, "");
        }
      })
        .catch((res) => {
          this.setState({
            loading: false,
          });
          let errorString = "";
          const errInt = parseInt(res.response.status / 100);
          let errMsg = httpErrors[errInt]
          errorString = errMsg !== undefined ? errMsg : res.message;
          // errorString = + ' Error';
          errorString += " Error";
            
          store.dispatch({
            type: Action.ACTION_SHOW_ERROR,
            msg: errorString
          });
        });
    })
  }

  loginClick = () => {
    this.setState({ loading: true, hideLogin: false }, () => {

      const requestBody = {
        userId: ""
      };
      const requestheader = {
        APP: "TED"
      };
      axios.post(
        this.serverContextUrl + this.urlLogin,
        requestBody,
        requestheader
      ).then(res => {
        if (res.data && res.data.success) {
          redirectWithCredentials(res.data.data, "");
        }
      })
        .catch((res) => {
          this.setState({
            loading: false,
          });
          let errorString = "";
          const errInt = parseInt(res.response.status / 100);
          let errMsg = httpErrors[errInt]
          errorString = errMsg !== undefined ? errMsg : res.message;
          // errorString = + ' Error';
          errorString += " Error";
            
          store.dispatch({
            type: Action.ACTION_SHOW_ERROR,
            msg: errorString
          });
        });
    })
  };

  gotoEmission() {
    if (storage.getObject('emissionRedirectingData')) {
      storage.remove("emissionRedirectingData");
    }
    const url = window.location.origin;
    redirectWithCredentials(url, '/ui/emission/');
  }

  gotoShipmentTrace = async () => {
    if(this.state.shipmentNumber === "") {
      store.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        msg: "Please input tracking number.",
        callback: () => {
          this.props.history.push("/");
        },
      });
      return;
    }

    // const cnvSearchKey = {
    //   'customerWaybill' : 'hblNo',
    //   'carrierWaybill'  : 'carrierWaybill',
    //   'bookingNo'       : 'referenceNos', 
    //   'containerNo'     : 'contNos',
    //   'referenceNo'     : 'referencesNo'
    // };
    const cnvSearchKey = {
      'customerWaybill' : 'hblNo',
      'carrierWaybill'  : 'mblNo',
      'bookingNo'       : 'bookingNo', 
      'containerNo'     : 'containerNo',
      'referenceNo'     : 'referenceNo'
    };
    const url = window.location.origin;
    const arg = encodeURI(this.state.shipmentNumber);
    const searchType = cnvSearchKey[this.state.shipmentNumbertype];

    // Check tracking number valid
    await axios
    .post("/shiptrace/guest/search/", { key: searchType, value: arg }, { headers: { APP: "CSS" } })
    .then((res) => {
      if (res && res.data && res.data.data && res.data.data.shipmentId) {
        redirectWithCredentials(url, `/shiptrace/?${ this.state.shipmentNumbertype }=${ arg }`);
        return;
      }

      store.dispatch({
        type: Action.ACTION_SHOW_INFO,
        msg: "No records found.\nPlease input valid tracking number.",
      });
    })
    .catch((res) => {
      let errorString = "";
      if (res.response) {
        const errInt = parseInt(res.response.status / 100);
        let errMsg = httpErrors[errInt];
        errorString = errMsg !== undefined ? errMsg : res.message;
        errorString += " Error";
      } else {
        errorString = res.message;
      }

      store.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        msg: errorString
      });
    });
  }

  shipmentTypeChange = (e) => {
    this.setState({
      shipmentNumbertype: e.target.value,
    });
  }
  
  render() {
    let imageArray = [
      { id: "i1", src: Slideshow1 },
      { id: "i2", src: Slideshow2 },
      { id: "i3", src: Slideshow3 },
      { id: "i4", src: Slideshow4 },
    ];
    const shipmentNumberType = [
      {type: 'customerWaybill', value: 'Customer Waybill'},
      {type: 'carrierWaybill', value: 'Carrier Waybill'},
      {type: 'bookingNo', value: 'Booking No'}, 
      {type: 'containerNo', value: 'Container No'},
      {type: 'referenceNo', value: 'Reference No'}
    ];
    let imgs = [];
    imageArray.map((item) => {
      imgs.push(<img id={item.id} src={item.src} alt="" />);
      return imgs;
    });

    let footerParams = { module: "login" };
    return (
      <>
        <div className="login-container cssPage">
          {this.state.loading ? (
            <Loading opacity={"0.5"} isNotLoginScreen={true} />
          ) : null}
          {/* <span className="sign-up-css">Sign up to CSS+</span> */}
          {!this.state.hideLogin ? (
            <div>
              <div className="fadein-css">{imgs}</div>
              <div>
                <img
                  src={bigger_underline}
                  className="longer-underline"
                  alt="line"
                />
              </div>

              <div className="card-container">
                <div className="shipment-search">
                  <div>
                    <div className="shipment-input-button">
                    <input
                      className="shipment-trace-input"
                      placeholder="Input tracking number:"
                      maxLength={40}
                      value={this.state.shipmentNumber}
                      onChange={(event) => this.setState({shipmentNumber:event.target.value})}
                    />
                    </div>
                    <div className="shipment-radio">
                    {shipmentNumberType.map((type) =>{
                        return <span className="shipment-radio-span"> <input type="radio" className="shipment-radio-input" checked={this.state.shipmentNumbertype === type.type} name="shipment-type" value={type.type} onChange={(event) => this.shipmentTypeChange(event)} /> <label> {type.value}</label> </span>
                    })}
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary shipment-trace-button shipment-search-icon"
                      onClick={this.gotoShipmentTrace}
                      type="search"></button>
                  </div>
                </div>
              </div>

              <div className="right-div-css">
                <div className="kwe-logo-css">
                  <img className="img-fluid" src={kwe_logo} alt="UFSLogo" />
                </div>
                <div className="login-form-container-css">
                  <div className="mt-55 row">
                    <span
                      className="headerSpan no-padding row no-margin"
                      style={{ color: "#0B3182" }}
                    >
                      <span>WELCOME&nbsp;</span>
                      <span
                        style={{
                          color: "#A6A6A6",
                          fontSize: "1.2rem",
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        to{" "}
                      </span>{" "}
                    </span>
                    <img
                      src={ufs_logo}
                      className="ufs-text-image"
                      alt="UFSLogo"
                    />
                  </div>
                  <div className="underline">
                    <img
                      src={underline}
                      style={{ width: "88%", height: "0.53vh" }}
                      alt="line"
                    />
                  </div>
                  <div
                    className="form-signin"
                    id="loginForm"
                    // onSubmit={this.handleSubmit}
                    method="POST"
                    noValidate
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {/* <img src={airplane} style={{ marginLeft: "-49px", marginRight: "auto" }} /> */}
                      <button
                        className="btn btn-lg btn-primary btn-block login-button-right"
                        type="submit"
                        onClick={this.loginClick}
                      >
                        Sign in
                      </button>
                      <NotificationDialog />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
                      <button
                        className="btn btn-lg btn-primary btn-block login-button-right signup"
                        type="submit"
                        onClick={this.signupClick}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
                <div className="kwe-emission-link">
                  <Link onClick={this.gotoEmission}>Go to KWE CO2 Calculator</Link> &#129122;<br />
                  <img alt="Img CO2 Calculator" src={Slideshow4} width="218px" ></img>
                </div>
                <div className="footnote">CUSTOMER SERVICE SYSTEM Plus</div>
              </div>
            </div>
          ) : null}
        </div>
        {!this.state.hideLogin &&
          <div className="footerDiv-css">
            <Footer {...footerParams} />
          </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Login);
