import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import './fonts/Avenir-Heavy.ttf';
import './fonts/Avenir-Roman.ttf'
import './fonts/Avenir-Light.ttf'
import App from "./App";
import { unregister } from "./serviceWorker";

unregister();
ReactDOM.render(<App />, document.getElementById("root"));
