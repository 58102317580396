export const Action = {
  /* COMMON ----- start ------ */
  ACTION_READ_NOTIFICATIONS: "ACTION_READ_NOTIFICATIONS",
  ACTION_SAVE_NOTIFICATIONS_CONFIG: "ACTION_SAVE_NOTIFICATIONS_CONFIG",
  ACTION_USER_LOGOUT: "ACTION_USER_LOGOUT",
  ACTION_USER_LOGOUT_AND_REDIRECT: "ACTION_USER_LOGOUT_AND_REDIRECT",
  ACTION_SAVE_NOTIFICATIONS_SETTINGS: "ACTION_SAVE_NOTIFICATIONS_SETTINGS",
  ACTION_SAVE_NOTIFICATIONS_FROM_SOCKETS: "ACTION_SAVE_NOTIFICATIONS_FROM_SOCKETS",
  ACTION_DATA_REFRESH_TRUE: "ACTION_DATA_REFRESH_TRUE",
  ACTION_DATA_REFRESH_FALSE: "ACTION_DATA_REFRESH_TRUE",
  ACTION_SAVE_NOTIFICATIONS_COUNT: "ACTION_SAVE_NOTIFICATIONS_COUNT",
  ACTION_CLEAR_NOTIFICATIONS: "ACTION_CLEAR_NOTIFICATIONS",
  ACTION_UPDATE_ICON_TYPE: "ACTION_UPDATE_ICON_TYPE",
  ACTION_LOADING: "ACTION_LOADING",
  ACTION_LOADED: "ACTION_LOADED",
  ACTION_SAVE_NOTIFICATION_ICONS: "ACTION_SAVE_NOTIFICATION_ICONS",
  ACTION_FILE_UPLOAD: "ACTION_FILE_UPLOAD",
  ACTION_ROLLER_LOADING: "ACTION_ROLLER_LOADING",
  ACTION_ROLLER_LOADED: "ACTION_ROLLER_LOADED",
  ACTION_CLEAR_CONFIG: "ACTION_CLEAR_CONFIG",
  
  ACTION_SHOW_ERROR: "ACTION_SHOW_ERROR",
  ACTION_SHOW_WARN: "ACTION_SHOW_WARN",
  ACTION_SHOW_INFO: "ACTION_SHOW_INFO",
  ACTION_DIALOG_HIDE: "ACTION_DIALOG_HIDE",

  ACTION_SHOW_POPUP: "ACTION_SHOW_POPUP",
  ACTION_HIDE_POPUP: "ACTION_HIDE_POPUP",

  ACTION_SHOW_CONFIRM: "ACTION_SHOW_CONFIRM",
  ACTION_HIDE_CONFIRM: "ACTION_HIDE_CONFIRM",

  ACTION_SHOW_OVERLAY: "ACTION_SHOW_OVERLAY",
  ACTION_HIDE_OVERLAY: "ACTION_HIDE_OVERLAY",
  /* COMMON ----- end ------ */

  /* CREATE CB actions ----- start ------ */

  ACTION_CB_LOAD_DATA: "ACTION_CB_LOAD_DATA",
  ACTION_CB_UPDATE_DATA: "ACTION_CB_UPDATE_DATA",
  ACTION_CB_LOAD_CONFIG: "ACTION_CB_LOAD_CONFIG",

  /* CREATE CB actions ----- end ------ */

  /* CONTAINER actions ----- start ------ */
  ACTION_CONTAINER_LOAD_CONFIG_FINISH: "ACTION_CONTAINER_LOAD_CONFIG_FINISH",
  ACTION_CONTAINER_UPDATE_FINISH: "ACTION_CONTAINER_UPDATE_FINISH",
  ACTION_CONTAINER_GET_DETAIL_FINISH: "ACTION_CONTAINER_GET_DETAIL_FINISH",
  ACTION_CONTAINER_GET_EVENT_LIST_FINISH: "ACTION_CONTAINER_GET_EVENT_LIST_FINISH",
  /* CONTAINER actions ----- end ------ */

  //====BEGIN: Shipping Instruction actions
  ACTION_SI_LOAD_CONFIG_FINISH: 'ACTION_SI_LOAD_CONFIG_FINISH',
  ACTION_SI_SEARCH_START: "ACTION_SI_SEARCH_START",
  ACTION_SI_SEARCH_FINISH: "ACTION_SI_SEARCH_FINISH",

  ACTION_SI_GET_COUNTRIES_FINISH: 'ACTION_SI_GET_COUNTRIES_FINISH',
  ACTION_SI_GET_REGIONS_FINISH: 'ACTION_SI_GET_REGIONS_FINISH',
  ACTION_SI_GET_PORTS_FINISH: 'ACTION_SI_GET_PORTS_FINISH',

  //====END: Shipping Instruction actions
  //====BEGIN: Carrier Bokking actions
  ACTION_CB_LOAD_CONFIG_FINISH: 'ACTION_CB_LOAD_CONFIG_FINISH',
  ACTION_CB_SEARCH_START: "ACTION_CB_SEARCH_START",
  ACTION_CB_SEARCH_FINISH: "ACTION_CB_SEARCH_FINISH",
  //====END: Carrier Booking actions
  //====BEGIN: Progress Overview actions
  ACTION_PO_LOAD_CONFIG_FINISH: 'ACTION_PO_LOAD_CONFIG_FINISH',
  ACTION_PO_SEARCH_START: "ACTION_PO_SEARCH_START",
  ACTION_PO_SEARCH_FINISH: "ACTION_PO_SEARCH_FINISH",
  ACTION_PO_LOAD_DATA: "ACTION_PO_LOAD_DATA",
  ACTION_PO_LOAD_CONFIG: "ACTION_PO_LOAD_CONFIG",
  ACTION_CLEAR_RESULT: "ACTION_CLEAR_RESULT",
  ACTION_PO_MAX_HEIGHT_CHANGE: "ACTION_PO_MAX_HEIGHT_CHANGE",
  ACTION_PO_UPADTE_USER_SEARCH: "ACTION_PO_UPADTE_USER_SEARCH",
  //====END: Progress Overview actions
  ACTION_STORE_SEARCH_RESULT: "ACTION_STORE_SEARCH_RESULT",
  // ACTION_STORE_SEARCH_QUERY: "ACTION_STORE_SEARCH_QUERY",
  ACTION_STORE_SWITCH: "ACTION_STORE_SWITCH",
  ACTION_STORE_FILTER_SWITCH: "ACTION_STORE_FILTER_SWITCH",

  ACTION_SWITCH_SEARCH_MODE: 'ACTION_SWITCH_SEARCH_MODE',
  ACTION_UPDATE_SEARCH_CRITERIA: 'ACTION_UPDATE_SEARCH_CRITERIA',
  ACTION_SAVE_AS_DEFAULT: 'ACTION_SAVE_AS_DEFAULT',
  ACTION_STORE_SAVEDSEARACH: 'ACTION_STORE_SAVEDSEARCH',

  ACTION_STORE_COMMENT_SEARCH_KEYWORD: 'ACTION_STORE_COMMENT_SEARCH_KEYWORD',
  ACTION_STORE_FILTER_CRITERIA: 'ACTION_STORE_FILTER_CRITERIA',

  ACTION_UPDATE_MODULE_NAME: 'ACTION_UPDATE_MODULE_NAME'


};
