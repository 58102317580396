import { createStore, applyMiddleware } from "redux";
import rootReducer from '../reducers/rootReducer';
import initialState from '../reducers/initialState';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import header from '../reducers/headerReducer';
import config from '../reducers/configReducer';
import notification from '../reducers/notificationReducer';
import socketMiddleware from '../middleware/socket';
// import notificationUI from '../reducers/notificationUIReducer';
import popup from '../reducers/popupReducer';
import confirm from '../reducers/confirmReducer';
import loading from '../reducers/loadingReducer';
import rollerLoading from '../reducers/rollerLoadingReducer';

// import cbReducer from '../reducers/cbReducer';
// import overviewReducer from '../reducers/overviewReducer';
// import shippingInstruction from '../reducers/siReducer';

import overlay from '../reducers/overlayReducer';
// let notificationUI={}, shippingInstruction={};
function createReducer(asyncReducers) {

 return combineReducers({
  rootReducer,
  header,
  config,
  loading,
  rollerLoading,
  notification,
  // notificationUI,
  popup,
  confirm,
  // shippingInstruction,
  // cbReducer,
  // overviewReducer,
  overlay,
    ...asyncReducers
  })

  
}


function configureStore(state) {

  // Add a dictionary to keep track of the registered async reducers
  let asyncReducers = {}
  let storeInit=  createStore(
    createReducer(),
    state,
    applyMiddleware(thunk, socketMiddleware)
  );

  // // Create an inject reducer function
  // // This function adds the async reducer, and creates a new combined reducer
 
    storeInit.injectReducer = (key, asyncReducer) => {
    asyncReducers[key] = asyncReducer
    storeInit.replaceReducer(createReducer(asyncReducers));
  
}
return storeInit;
}






let store = configureStore(initialState);
export default store;
