import React, { Component } from "react";
import "./Fallback.scss";
import Version from "../Layout/Version";
import store from "../../store/store";
import { connect } from "react-redux";
import { Action } from "../../actions/ActionType";
import storage from '../../common/services/storage';

class LoginFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    store.dispatch({
      type: Action.ACTION_UPDATE_MODULE_NAME, 
      moduleName: ''});
  }
  toLogin = () => {
    storage.flush(false);
    this.props.history.push("/");
  };
  render() {
    return (
      <div>
        <div className="fallback-div">
          <h1 className="header-message">Error</h1>
          <h5 className="text-message">
            Login failure. Please try again.
        </h5>
          <button
            className="btn btn-primary navigate-button"
            onClick={this.toLogin}
          >    
          Back to Login      
        </button>
        </div>
        <Version />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
  }
}

export default connect(mapStateToProps)(LoginFailed);
