import React, { Component } from "react";
import "../Layout/version.scss";
import buildInfo from "../../build.json";

class Version extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let versionClass = this.props.module && this.props.module === "login" ? "version-login" : "version"
    // Decide what to show in the version place holder
    let bVersion = ''
    if (buildInfo && buildInfo.buildVersion)
      bVersion = buildInfo.buildVersion

    // Render version
    return (
      <div className={versionClass}>
        {bVersion}
      </div>
    );
  }
}

export default Version;
