import { httpGet } from "../services/ajaxService";

import configurations from '../../services/config/configurations.json';
import storage from './storage.js';


const KEY_SERVER_URL_CONFIG = 'serverUrlConfig';
export class AppConfigService {

  static async getApps() {
    let serverUrlConfig = storage.getObject(KEY_SERVER_URL_CONFIG);
    if (serverUrlConfig) {
      return serverUrlConfig;
    }

    if (window.location.host.startsWith('localhost')) { // for DEV
      serverUrlConfig = configurations;
      storage.setObject(KEY_SERVER_URL_CONFIG, serverUrlConfig, false);
      return serverUrlConfig;
    } 
    else { 
      let serverUrlPathConfig = await httpGet('/secure/configurations/');
      if(serverUrlPathConfig){
        // const tedPath = serverUrlPathConfig.find(item => item.id === "ted");
        const shiptracePath = serverUrlPathConfig.find(item => item.id === "shipment");
      serverUrlConfig = [{
        id: 'ted',
        url: window.location.origin
      },
      {
        id: 'shipment',
        url: window.location.origin + shiptracePath.url
      }
    ]
      storage.setObject(KEY_SERVER_URL_CONFIG, serverUrlConfig, false);
      return serverUrlConfig;
    }
   }
  }
}
