import { Action } from "../actions/ActionType";
import initialState from "./initialState";
import { si_master_data } from "./si_master_data"

export default function configReducer(state = initialState.config, action) { //NOSONAR
  const config = action.data;
  const uiPageJson = action.uiPageJson;
  switch (action.type) {
    case Action.ACTION_CONTAINER_LOAD_CONFIG_FINISH:
      /* change key name only */
      const container = {
        containerGrid: config.containerGrid,
        containerDetailGrid: config.containerDetailGrid,
        gridColumnOps: config.gridColumnOps,
        containerTypes: config.types,
        savedSearch: config.userSearch,
        timezoneList: config.timezoneList,
        milestoneGrid: config.milestoneGrid,
        eventList: config.events,
        uiPageJson: uiPageJson,
        isLoaded: true
      };
      return { ...state, container };

    case Action.ACTION_SI_LOAD_CONFIG_FINISH:
      const siWizard = config.new.components.find(e => { return e.id === 'editshippingInstructions' });
      const shippingInstruction = {
        gridColumnOps: config.gridColumnOps,
        codes: config.codes,
        actualCargoGrid: config.actualCargoGrid,
        carriageTransportTypes: config.carriageTransportTypes,
        timezoneList: config.timezoneList,
        movementTypes: config.movementTypes,
        selectedMenu: config.selectedMenu,
        siStatuses: config.siStatuses,
        siFieldConfig: config.siFieldConfig,
        create: config.create,
        shippingInstructionId: config.shippingInstructionId,
        serviceTypes: config.serviceTypes,
        userSearch: config.userSearch,
        shippingInstructionGrid: config.shippingInstructionGrid,
        bookedCargoGrid: config.bookedCargoGrid,
        containerTypes: config.containerTypes,
        termTypes: si_master_data.chargeTerms,
        cargoPartnerLabelMapping: si_master_data.cargoPartnerLabelMapping,
        labelNameMapping: si_master_data.labelNameMapping,
        siWizard: siWizard,
        deptId: config.deptId,
        uiPageJson: uiPageJson,
        isLoaded: true
      };
      return { ...state, shippingInstruction };
    case Action.ACTION_CB_LOAD_CONFIG_FINISH:
      const carrierBooking = {
        gridColumnOps: config.gridColumnOps,
        freightTerms: config.freightTerms,
        blTypes: config.blTypes,
        movementTypes: config.movementTypes,
        carriageTransportTypes: config.carriageTransportTypes,
        carrierBookingCancelReasons: config.carrierBookingCancelReasons,
        chargeTerms: config.chargeTerms,
        ediServiceTypes: config.ediServiceTypes,
        ediMovementTypes: config.ediMovementTypes,
        ediContainerTypes: config.ediContainerTypes,
        carrierWaybillTypes: config.carrierWaybillTypes,
        siStatusList: config.siStatusList,
        serviceTypes: config.serviceTypes,
        userSearch: config.userSearch,
        carrierBookingGrid: config.carrierBookingGrid,
        carrierTypes: config.carrierTypes,
        statusOptions: config.statusOptions,
        ediStatusOptions: config.ediStatusOptions,
        oceanScheduleGrid: config.oceanScheduleGrid,
        oceanScheduleDetailsGrid: config.oceanScheduleDetailsGrid,
        scheduleTypeList: config.scheduleTypeList,
        searchSIGrid: config.searchSIGrid,
        containerTypes: config.containerTypes,
        siPrivilegeBasedOnStatus: "",
        timezoneList: [],
        selectedMenu: "recentSearch",
        siFieldConfig: "",
        create: true,
        shippingInstructionId: "",
        bookedCargoGrid: {
          gridColumns: [],
          itemsPerPage: 10,
          totalItems: 100
        },
        uiPageJson: uiPageJson,
        isLoaded: true
      };
      return { ...state, carrierBooking };
    case Action.ACTION_PO_LOAD_CONFIG_FINISH:
      const progressOverview = {
        gridColumnOps: config.gridColumnOps,
        exportImportSwitch: config.OVERVIEW_ImportExportSwitch,
        siStatusList: config.siStatusList,
        serviceTypes: config.serviceTypes,
        userSearch: config.userSearch,
        progressOverviewGrid: config.grid,
        busProcessStatuses: config.busProcessStatus,
        carrierTypes: config.carrierTypes,
        statusOptions: config.caseStatus,
        transportModes: config.transportModes,
        searchSIGrid: config.searchSIGrid,
        containerTypes: config.containerTypes,
        siPrivilegeBasedOnStatus: "",
        timezoneList: [],
        selectedMenu: "recentSearch",
        siFieldConfig: "",
        create: true,
        shippingInstructionId: "",
        bookedCargoGrid: {
          gridColumns: [],
          itemsPerPage: 10,
          totalItems: 100
        },
        uiPageJson: uiPageJson,
        isLoaded: true,
        carriageTransportTypes : config.carriageTransportTypes 
      };
      return { ...state, advance: action['advance'], quick: action['quick'], active: action['active'], autoId: action['autoId'], progressOverview };
    case Action.ACTION_PO_UPADTE_USER_SEARCH:
      const updatedProgressOverview = { ...state.progressOverview, userSearch: action.data };
      return { ...state, progressOverview: updatedProgressOverview };
    case Action.ACTION_SWITCH_SEARCH_MODE:
      const activeMode = action['mode'];
      return { ...state, active: activeMode };
    case Action.ACTION_UPDATE_SEARCH_CRITERIA:
      const modeData = action['criteria'];
      if (action['autoId']) {
        return { ...state, [modeData.mode]: modeData, autoId: action['autoId'] };
      } else {
        return { ...state, [modeData.mode]: modeData };
      }
    case Action.ACTION_SAVE_AS_DEFAULT:
      return { ...state, saveAsDefault: action['saveAsDefault'] };
    case Action.ACTION_CLEAR_CONFIG:
      return { ...initialState.config }
    default:
      return state;
  }
}
