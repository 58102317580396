import { Action } from "../actions/ActionType";
import initialState from "./initialState";

export default function overlayReducer(
  state = initialState.overlay, //NOSONAR
  action
) {
  switch (action.type) {
    case Action.ACTION_SHOW_OVERLAY:
      return {
        isReduxAction: true,
        visible: true,
        item: action.item,
        wrapperClassName: action.wrapperClassName,
        args: action.args,
        showButton: action.showButton || false,
        button: action.button,
        onClosing: action.onClosing,
        onClosed: action.onClosed
      };
    case Action.ACTION_HIDE_OVERLAY:
      return { visible: false };
    default:
      return state;
  }
}
