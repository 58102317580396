export const si_master_data = {
    labelNameMapping : {
    'busOwner': "Business Owner Name",
    'shipper': "Shipper Name",
    'consignee': "Consignee Name",
    'notify': "Notify Name",
    "waybillType": "Carrier Waybill Type",
    "quote": "Quote No.",
    "serviceType": "Service Type",
    "incoTerms": "Incoterms",
    "chargeTerm": "Charge Term",
    "freightTerm": "Freight Term",
    "destChargeTerm": "Dest Charge Term",
    "noOfCopies  ": "No. of Copies",
    "licenseNo": "License Number",
    "prcpt": "Place of Receipt - Code",
    "prcptName": "Place of Receipt - Name",
    "portd": "Port of Discharge - Code",
    "portdName": "Port of Discharge - Name",
    "finalDestination": "Final Destination - Code",
    "finalDestinationName": "Final Destination - Name",
    "portl": "Port of Loading - Code",
    "portlName": "Port of Loading - Name",
    "pdlvy": "Place of Delivery - Code",
    "pdlvyName": "Place of Delivery - Name",
    "pickupRequest": "Pickup Request",
    "pickupLocation": "Pickup Location",
    "pickupDate": "Pickup Date",
    "noOfContainers": "No Of Container",
    "contType": "Container Type",
    "pieces" : "Pieces",
    "packageType": "package Type",
    "slacstc": "SLAC/STC",
    "slacstUom": "SLAC/STC UOM",
    "contNo": "Container No",
    "sealNo": "Seal No",
    "desc": "Description",
    "measurement" : "Measurement",
    "measurementUom": "Measurement UOM",
    "grossWeight": "Gross Weight",
    "grossWeightUom": "GrossWeight UOM",
    "addressText": "Partner Address",
    "contactText": "Partner Contact",
    "contactfirstname": "Partner Contact Firstname",
    "contactlastname": "Partner Contact Lastname",
    "partnerId": "Partner ID",
    "partnerName": "Partner Name",
    "serviceLocation.freightTerm": "Freight Term",
    "serviceLocation.chargeTerm": "Charge Term",
    "serviceLocation.incoTerms": "Inco Term",
    "serviceLocation.movementType": "Movement Type",
    "serviceLocation.noOfCopies": "No Of Copies",
    "serviceLocation.destChargeTerm": "Dest Charge Term",
    "actualCargo" : "Actual Cargo",
    "customerInfo.shipper.partnerId": "Shipper ID",
    "customerInfo.shipper.partnerName": "Shipper Name",
    "customerInfo.shipper.address": "Shipper Address",
    "customerInfo.consignee.partnerId": "Consignee ID",
    "customerInfo.consignee.partnerName": "Consignee Name",
    "customerInfo.consignee.address": "Consignee Address",
    "customerInfo.notify.partnerId": "Notify ID",
    "customerInfo.notify.partnerName": "Notify Name",
    "customerInfo.notify.address": "Notify Address",
    "customerInfo.controllingParty.partnerId": "Controlling Party ID",
    "customerInfo.controllingParty.partnerName": "Controlling Party Name",
    "customerInfo.controllingParty.address": "Controlling Party Address",
    "customerInfo.exportBillTo.partnerId": "Export Bill To ID",
    "customerInfo.exportBillTo.partnerName": "Export Bill To Name",
    "customerInfo.exportBillTo.address": "Export Bill To Address",
    "serviceLocation.serviceType": "Service Type",
    "serviceLocation.blType": "BL Type",
    "serviceLocation.portOfLoading.portCode": "Port of Loading Code",
    "serviceLocation.portOfLoading.portName": "Port of Loading Name",
    "serviceLocation.portOfDischarge.portCode": "Port of Discharge Code",
    "serviceLocation.portOfDischarge.portName": "Port of Discharge Name",
    "serviceLocation.placeOfReceipt.portCode": "Port of Receipt Code",
    "serviceLocation.placeOfReceipt.portName": "Port of Receipt Name",
    "serviceLocation.placeOfDelivery.portCode": "Port of Delivery Code",
    "serviceLocation.placeOfDelivery.portName": "Port of Delivery Name",
    "scheduleInfo.carrierBookingNo": "Carrier Booking No.",
    "actualCargo.pieces": "Pieces",
    "actualCargo.packageType": "Package Type",
    "actualCargo.slacstc": "SLAC/STC",
    "actualCargo.slacstcUom": "SLAC/STC  - UOM",
    "bookedCargo.noOfContainer": "No of Containers",
    "bookedCargo.containerType": "Container Type",
    "bookedCargo.description": "Description",
    "actualCargo.containerType": "Container Type",
    "actualCargo.containerNo": "Container No",
    "actualCargo.sealNo": "Seal No.",
    "actualCargo.description": "Description",
    "actualCargo.measurement": "Measurement",
    "actualCargo.measurementUom": "Measurement –UOM",
    "actualCargo.grossWeight": "Gross Weight",
    "actualCargo.grossWeightUom": "Gross Weight – UOM",
    "bookedCargo.pickupDate": "Pickup Date",
    "bookedCargo.Customer.address": "Customer Address",
    "actualCargo.pickupDate": "Pickup Date",
    "actualCargo.Customer.address": "Customer Address"
  },
  
  cargoPartnerLabelMapping : {
    "partnerId": "Customer ID",
    "partnerName": "Customer Name",
    "addressText": "Customer Address",
    "contactText": "Customer Contact",
    "contactfirstname": "Customer Contact Firstname",
    "contactlastname": "Customer Contact Lastname"
  },
  chargeTerms : [
    {"cdDescription":"Collect","cdCode":"C"},
    {"cdDescription":"Prepaid","cdCode":"P"}
  ]
}