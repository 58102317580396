import { httpGet } from "./ajaxService";
import storage from "./storage";

export const getMsgByIds = (msgIds, params) => {
  const messages = storage.getObject('messages');
  let msg;
  if (messages) {
    msgIds.forEach(msgId => {
      const m = messages[msgId] || "";
      if (msg) {
        msg = msg.concat("\r\n").concat(m);
      } else {
        msg = m;
      }
    });

    if (params !== undefined) {
      return addValuesToMsg(msg, params);
    } else {
      return msg;
    }
  }
}

export const getMsgById = (msgId, params) => {
  // TODO: to avoid deserialize JSON everytime, 
  // consider copying messages from session to redux's store, if user refresh page then re-copy again.
  const messages  = storage.getObject('messages');
  if (messages) {
    const msg = messages[msgId] || "";
    if (params !== undefined) {
      return addValuesToMsg(msg, params);
    } else {
      return msg;
    }
  }
}

const addValuesToMsg = (msg, params) => {
  let m = msg;
  for (const key in params) {
    m = m.replace('${'.concat(key).concat('}'), params[key]);
  }
  return m;
};

export const loadMsgToStorage = () => {
  httpGet("/secure/api/messages/all/", null, 'json', false).then(res => {
    if (res && res.success) {
      storage.setObject('messages', res.data, false);
    }
  });
}
