import React, { Component } from "react";
import Header from "./Header";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { connect } from "react-redux";

class PageContent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <Header history={this.props.history} screenName={this.props.moduleName || ""} />
                <Routes history={this.props.history} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        moduleName: state.header.moduleName
    }
}

export default withRouter(connect(mapStateToProps)(PageContent));
