import React, { Component } from "react";

import "./Fallback.scss";
import Version from "../Layout/Version";
import store from "../../store/store";
import { connect } from "react-redux";
import { Action } from "../../actions/ActionType";

class Fallback extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    store.dispatch({
      type: Action.ACTION_UPDATE_MODULE_NAME, 
      moduleName: ''});
  }
  // toLanding = () => {
  //   this.props.history.push("/secure/landing/");
  // };
  render() {
    return (
      <div>
        <div className="fallback-div">
          <h1 className="header-message">Error - 404</h1>
          <h5 className="text-message">
            The page you are looking for is not available
        </h5>
          {/* <button
            className="btn btn-primary navigate-button"
            onClick={this.toLanding}
          >
           
        </button> */}
        </div>
        <Version />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
  }
}

export default connect(mapStateToProps)(Fallback);
