import React from "react";
import { connect } from "react-redux";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Action } from "../../actions/ActionType";

class ReadOnlyPopup extends React.Component {
  togglePopup = content => {
    this.props.dispatch({ type: Action.ACTION_HIDE_POPUP });
    if (this.props.callback !== undefined) {
      this.props.dispatch({ type: this.props.callback });
    }
  };

  render() {
    if (!this.props.visible) {
      return '';
    }
    return (
      <Dialog
        className={this.props.className}
        width={this.props.width}
        title={this.props.title}
        onClose={this.togglePopup}
      >
        {this.props.data}
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    visible: state.popup.visible,
    title: state.popup.title,
    data: state.popup.data,
    className: state.popup.className,
    width: state.popup.width,
    callback: state.popup.callback,  // callback action type
  };
}

export default connect(
  mapStateToProps,
  null
)(ReadOnlyPopup);
