/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withRouter } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./sidebar.scss";
// import storage from "../../common/services/storage"
import { redirectWithCredentials } from "../../common/services/ajaxService";
import { AppConfigService } from "../../common/services/AppConfigService";
import menuIcon from "../../assets/icons/main-icons.svg";
import  CSS_Text from "../../assets/logos/CSS_Text.png";
import data from "../../services/config/landingCardData.json";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      itemdata: data
    };
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }


  goToPage = async ({ appId, targetUrl }) => {
    AppConfigService.getApps().then(apps => {
      const app = apps.find(item => item.id === appId);
      if (app) {
        const currentHost = window.location.origin;
        if (currentHost === app.url) {
          this.props.history.push(targetUrl);
        } else {
          redirectWithCredentials(app.url, targetUrl);
        }
      }
    });
  };

  
  render() {
    let itemMenus = this.state.itemdata.sort((a,b) => a.orderNumber - b.orderNumber > 0 ? 1 : -1).map(item => {
      return <div key={item.orderNumber} className={item.className} onClick={() => this.closeMenu() } >
        <a onClick={() => this.goToPage({appId: item.appId, targetUrl: item.targetUrl})} >{item.name}</a>
      </div>
    });

    
    return (
      <Menu
        customBurgerIcon={<img alt="menu" src={`${menuIcon}#menu`} />}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        {itemMenus}
        <div className="sidebar-header" onClick={() => this.toggleMenu()} >
          <h1 className="logo-header"><img className="css_header_sidebar" src={CSS_Text} alt="Customer Service System Plus Text logo"></img></h1>
          </div>
      </Menu> 
    );
  }
}

export default withRouter(Sidebar);
