import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./dialog.scss"
import { connect } from "react-redux";
import { Action } from "../../actions/ActionType";
import { getMsgById, getMsgByIds } from "../../common/services/commonService";
const BTN_CLASS_NAME = 'ted-dialog-btn ted-btn-sm ted-btn'
const PRIMARY_BTN_CLASS_NAME = BTN_CLASS_NAME + ' ted-primary-btn'
const SECONDARY_BTN_CLASS_NAME = BTN_CLASS_NAME + ' ted-secondary-btn'
class NotificationDialog extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  onClick = (e, btnTxt) => {
    this.props.dispatch({ type: Action.ACTION_DIALOG_HIDE });
    const buttons = this.buildButtons(this.props)
    for (var i = 0; i < buttons.length; i++) {
      const btn = buttons[i];
      if (btnTxt === btn.text) {
        this.doCallback(btn.callback)
        break;
      }
    }
  }
  onClose = () => {
    this.props.dispatch({ type: Action.ACTION_DIALOG_HIDE });
    if (this.props.onClose) {
      this.doCallback(this.props.onClose)
    } else if (!this.props.buttons || this.props.buttons.length === 0) {
      //TRICKY: if there is no button and onClose provided, and default OK is created, 
      //the close action will be same with hit the button. Support current behavior.

      this.doCallback(this.props.callback)
    }
  }

  doCallback = (callback) => {
    if (callback && typeof callback === 'function') {
      callback()
    } else if (callback) {
      this.props.dispatch({ type: callback });
    }
  }

  buildButtons = (props) => {
    let buttons = props.buttons || [];
    if (buttons.length === 0) {
      //if there is no buttons provided, then render default OK button
      buttons.push({ text: 'OK', isPrimary: true, callback: props.callback })
    }
    return buttons;
  }

  render() {
    if (!this.props.visible) {
      return '';
    }
    let classname;
    if (this.props.type === 'ERROR') {
      classname = 'k-icon k-i-error';
    } else if (this.props.type === 'WARNING') {
      classname = 'k-icon k-i-warning';
    } else { // INFO & others
      classname = 'k-icon k-i-information';
    }
    let msg;
    if (this.props.msgId !== undefined) {
      msg = getMsgById(this.props.msgId, this.props.msgParams);
    } else if (this.props.msgIds !== undefined) {
      msg = getMsgByIds(this.props.msgIds, this.props.msgParams);
    } else {
      msg = this.props.msg;
    }

    let buttons = this.buildButtons(this.props)

    let renderButtons = buttons.map((btn, index) => {
      const className = btn.isPrimary ? PRIMARY_BTN_CLASS_NAME : SECONDARY_BTN_CLASS_NAME;
      return (
        <input key={index}
          type="button"
          value={btn.text}
          autoFocus={btn.isPrimary}
          className={className}
          onClick={(e) => this.onClick(e, btn.text)}
        />)
    });

    return (


      <div className="ted-dialog">
        <Dialog title={this.props.title} onClose={this.onClose} className="modal-dlg-wd-header">
          <div className="msg-info">
            <div className={classname}></div>
            <div className="custom-scrollbar msg-content">
              {msg}
            </div>
          </div>
          <DialogActionsBar>
            {renderButtons}

            {/* {this.props.secondaryButtonText && (
              <input
                type="button"
                value={this.props.secondaryButtonText || "Cancel"}
                className="ted-dialog-btn ted-btn-sm ted-btn ted-secondary-btn"
                onClick={this.onSecondaryButtonClick}
              />
            )}

            <input
              type="button"
              value={this.props.actionButtonText || "OK"}
              autoFocus={true}
              className="ted-dialog-btn ted-btn-sm ted-btn ted-primary-btn"
              onClick={() => { this.toggleDialog(); }}
            /> */}
          </DialogActionsBar>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    visible: state.notification.visible,
    msgIds: state.notification.msgIds,
    msgId: state.notification.msgId,
    msgParams: state.notification.msgParams,
    msg: state.notification.msg,
    callback: state.notification.callback,  // callback action type when the OK button is hit
    title: state.notification.title,
    type: state.notification.type,
    buttons: state.notification.buttons, // customized buttons in case not provide default OK button
    onClose: state.notification.onClose // callback when the Close button is hit.
    // actionButtonText: state.notification.actionButtonText,
    // noCallbackOnCloseButton: state.notification.noCallbackOnCloseButton,
    // secondaryButtonText: state.notification.secondaryButtonText,
    // secondaryButtonClickCallback: state.notification.secondaryButtonClickCallback
  };
}

export default connect(
  mapStateToProps,
  null
)(NotificationDialog);
