import React, { Component } from "react";

import "./SignupProcessing.scss";
import Footer from "../Layout/Footer";
import store from "../../store/store";
import { connect } from "react-redux";
import { Action } from "../../actions/ActionType";
import Header from "../Layout/Header";
import Loading from "../../components/Common/Loading";
import { loadMsgToStorage } from "../../common/services/commonService";
import axios from "axios";
import storage from "../../common/services/storage";


class SignupProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userName: ""
    };
    store.dispatch({
      type: Action.ACTION_UPDATE_MODULE_NAME,
      moduleName: "",
    });
  }

componentDidMount = () => {
    this.setState({ loading: true}, () => {
      this.getProfileData().then((res) => {
        if (res && res.data) {
          const { data } = res.data;

          storage.set("userName", data.login.name, false);
          storage.set("alertTime", data.alertTime, false);
          storage.set("sessionTimeout", data.sessionTimeout, false);
          storage.setObject("profileData", data, false);
          loadMsgToStorage();
          this.setState({
            loading: false,
            userName : data.login.name
            // hideLogin: false,
          });
        } else {
          this.setState({
            loading: false,
          });
          store.dispatch({
            type: Action.ACTION_SHOW_ERROR,
            msg: "Failed to fetch the response",
          });
        }
      });
    });
}

async getProfileData() {
  let menuDataResponse = await axios
    .get("/secure/landing/menuprofile/", { headers: { APP: "TED" } })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
    });
  return {
    ...menuDataResponse,
  };
}

  render() {
    return (
      <div>
        {this.state.loading ? (
            <Loading opacity={"0.5"} isNotLoginScreen={true} />
          ) : null}
        <Header history={this.props.history} screenName="" hideSidebar= {true} userName= {this.state.userName} />
        <div className="main-div">
          <h1 className="header-message"> </h1>
          <h5 className="text-message">
          Please contact your Sales Representative and/or Sales Office to complete the setup
          </h5>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(SignupProcessing);
