import {Action} from "../actions/ActionType";
import initialState from "./initialState";

export default function rollerLoadingReducer(state = initialState.loading, action) { //NOSONAR
  switch (action.type) {
    case Action.ACTION_ROLLER_LOADING:
      return (state + 1);
    case Action.ACTION_ROLLER_LOADED:
      return (state - 1);
    default:
      return state;
  }
}
