import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import Header from './components/layout/Header'

import "./styles/overview.scss";
import "./styles/layout.css";
import "./styles/html5reset-1.6.1.css";
import "./styles/print.css";
import "./styles/jquery.mCustomScrollbar.css";
import "./styles/App.scss";

import Loading from "./components/Common/Loading";
import NotificationDialog from "./components/Common/NotificationDialog";
import ConfirmationDialog from "./components/Common/ConfirmationDialog";
import SignupProcessing from "./components/Common/SignupProcessing";
import LoginFailed from "./components/Common/LoginFailedPage";
import Fallback from "./components/Common/Fallback";
import ReadOnlyPopup from "./components/Common/ReadOnlyPopup";
import { Provider } from "react-redux";
import store from "./store/store";
import RollerLoading from "./components/Common/Loading/RollerLoading";
import OverlayScreen from "./components/Common/OverlayScreen/OverlayScreen";
import CSSLoginUpdated from "./components/Login/CSSLoginUpdated";
import PageContent from "./components/Layout/PageContent";


class App extends Component {

  render() {
    return (
      <Router>
        <Provider store={store} className="App">
          <Loading />
          <RollerLoading />
          <Switch>
            <Route path="/" exact component={CSSLoginUpdated} />
            <Route path="/signupprocessing" component={SignupProcessing} />
            <Route path="/LoginFailed" component={LoginFailed} />
            <Route path="/error" component={Fallback} />
            <Route path="*" component={Fallback} />
            <PageContent history={this.props.history} />
          </Switch>
          <NotificationDialog />
          <ReadOnlyPopup />
          <ConfirmationDialog />
          <OverlayScreen />
        </Provider>
      </Router>
    );
  }
}
export default App;
