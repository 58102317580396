import React, { Component } from "react";
import { connect } from "react-redux";
// import { Popup } from "@progress/kendo-react-popup";
import { Action } from "../../actions/ActionType";
import Sidebar from "../Sidebar/Sidebar";
import storage from "../../common/services/storage"
import "./header.scss";
import store from '../../store/store';
import CSS_Text from "../../assets/logos/CSS_Text.png"
import menuIcon from "../../assets/icons/main-icons.svg";
class Header extends Component {
  unsubscribeStore;

  constructor(props) {
    // console.log("Header construct");
    super(props);
    this.state = {
      showUserProfilePopup: false,
    };
    // if (storage.get("userName") === null) {
    //   this.props.history.push("/");
    // }

  }


  
  componentDidMount = () => {
     this.unsubscribeStore = store.subscribe(() => {
    })
  };

  componentWillUnmount = () => {
    this.unsubscribeStore && this.unsubscribeStore();
  };

  
   logoutClick = () => {
    this.props.dispatch({
      type: Action.ACTION_USER_LOGOUT,
      history: this.props.history,
    });
  };




  render() {
    return (
      <div id="header">
         {this.props.hideSidebar ? <img alt="menu" src={`${menuIcon}#menu`} className="burger-button" style={{ cursor: "default" }} />
          : <Sidebar />}
        <h1>
          <img className="css_header" src={CSS_Text} alt="CSS"></img>
        </h1>{" "}
        <div id="h2">{this.props.screenName}</div>
        {/* <div id="nort"><span>4</span></div> */}
        <div className="userDetailsContainer">
          <div className="userInfo" >
            <span className="userProfile">
              {this.props.userName ? this.props.userName : storage.get("userName")}
            </span>
          </div>
          <span className="vertLine"> | </span>
          <span className="logout" onClick={e => this.logoutClick()}>
            {" "}
            Sign Out{" "}
          </span>
        </div>
       
      </div>
    );
  }

}

function mapStateToProps(state) {
   return {
    profileData: state.header.profileData,
    menuData: state.header.menuData,
    clearState: state
  }
}

export default connect(mapStateToProps)(Header);
