import React from "react";
import { connect } from "react-redux";
import "./OverlayScreen.scss";
import { withRouter } from "react-router-dom";
import { Action } from "../../../actions/ActionType";
import NavigationPrompt from "react-router-navigation-prompt";
class OverlayScreen extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      if (prevProps.location.pathname !== this.props.location.pathname) { //Always close overlay on route changing.
        this.close();
      }
    }
  }

  onClosing = (passedArgs) => {
    let result = true;
    if (this.props.onClosing) { //Call closing method from controlling component
      result = this.props.onClosing(this.props.args);
    }
    return result;
  }

  handleCloseOverlay = (passedArgs) => {
    let closeable = this.onClosing(passedArgs);
    if (closeable) {
      this.close(passedArgs);
    } else {
      this.props.dispatch({
        type: Action.ACTION_SHOW_CONFIRM,
        msgId: "LEAVE_WARNING",
        yes: () => this.close(passedArgs),
      });
    }
  };

  close = (passedArgs) => {
    if (this.props.onClosed) {
      this.props.onClosed(this.props.args);
    }
    this.closeOverlay(passedArgs);
  }

  closeOverlay = (passedArgs) => {
    if (this.props.isReduxAction) {
      this.props.dispatch({ type: Action.ACTION_HIDE_OVERLAY });
    } else {
      this.props.closeOverlay(passedArgs);
    }
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    //If button is not passed, use default button
    const button = this.props.button || (
      <div className="overlay-btn-holder">
        <button
          className="k-button overlay-back-btn"
          onClick={this.handleCloseOverlay}
        >
          <span role="presentation" className="k-icon k-i-arrow-left" /> Back
        </button>
      </div>
    );

    return (
      <React.Fragment>
        <div className={`ted-overlay-screen ${this.props.wrapperClassName || ""}`}>
          {this.props.showButton && button}
          {React.cloneElement(this.props.item, { handleCloseOverlay: this.handleCloseOverlay }) || "You forgot to pass the item."}
        </div>
        <NavigationPrompt when={(crntLocation, nextLocation) => !this.onClosing()}>
          {({ onConfirm, onCancel }) => {
            this.props.dispatch({
              type: Action.ACTION_SHOW_CONFIRM,
              msgId: "LEAVE_WARNING",
              yes: () => { this.close(); onConfirm() },
              no: onCancel
            });
          }}
        </NavigationPrompt>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isReduxAction: true,
    visible: state.overlay.visible,
    item: state.overlay.item,
    wrapperClassName: state.overlay.wrapperClassName,
    showButton: state.overlay.showButton,
    args: state.overlay.args,
    button: state.overlay.button,
    onClosing: state.overlay.onClosing,
    onClosed: state.overlay.onClosed
  };
}

// Let ownProps has higher order than stateProps
const mergeProps = (stateProps, dispatchProps, ownProps) => (Object.assign({}, stateProps, dispatchProps, ownProps))

export default connect(
  mapStateToProps,
  null,
  mergeProps
)(withRouter(OverlayScreen));
