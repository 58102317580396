import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { connect } from "react-redux";
import { Action } from "../../actions/ActionType";
import { getMsgById } from "../../common/services/commonService";
import "./dialog.scss"
class ConfirmDialog extends React.Component {

  hideDialog = () => {
    if (typeof this.props.close === 'function') {
      this.props.close();
    }
    this.props.dispatch({ type: Action.ACTION_HIDE_CONFIRM });
  };

  handleDialog = event => {
    if (event.target.value === "Yes") {
      if (typeof this.props.yes === 'function') {
        this.props.yes();
      } else {
        this.props.dispatch({ type: this.props.yes });
      }
    } else {
      if (this.props.no !== undefined) {
        if (typeof this.props.no === 'function') {
          this.props.no();
        } else {
          this.props.dispatch({ type: this.props.no });
        }
      }
    }
    this.hideDialog();
  };

  render() {
    if (!this.props.visible) {
      return null;
    }
    let msg;
    if (this.props.msgId !== undefined) {
      msg = getMsgById(this.props.msgId, this.props.msgParams);
    } else {
      msg = this.props.msg;
    }
    return (
      <div>
          <Dialog title={this.props.title} onClose={this.hideDialog} className="modal-dlg-wd-header">
            <div className="msg-info">
              <p className="msg-content">
                {msg}
              </p>
            </div>
            <DialogActionsBar>
              <div className="cfrm-dlg-action-bar">
                <input
                  type="button"
                  value="Yes" tabIndex="0"
                  autoFocus={true}
                  className="ted-dialog-btn ted-btn-sm ted-btn ted-primary-btn"
                  onClick={this.handleDialog}
                />
                <input
                  value="No"
                  className="ted-dialog-btn ted-btn-sm ted-btn ted-secondary-btn"
                  onClick={this.handleDialog}
                  type="button"
                />
              </div>
            </DialogActionsBar>
          </Dialog>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    visible: state.confirm.visible,
    msg: state.confirm.msg,
    msgId: state.confirm.msgId,
    msgParams: state.confirm.msgParams,
    yes: state.confirm.yes,
    no: state.confirm.no,
    close: state.confirm.close,
    title: state.confirm.title
  };
}

export default connect(
  mapStateToProps,
  null
)(ConfirmDialog);

