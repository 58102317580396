
import storage from '../common/services/storage';
import ReconnectingWebSocket from 'reconnecting-websocket';
// import { Action } from '../actions/ActionType';


// some contraints:
// + user hits browser's refresh button, ws connection is gone, need to re-open
// + network may down for a certain time, need to re-open when it's up
// + retry if fail when establishing connection, display error if retry fail
// + there must be only 1 connection at a time
// + avoid server flood when many clients connect to notification service at the same time (e.g: after service restart)

// so we need to deal with:
// + open new connection after logged in and close before logging out (handled in Login/Logout action)
// + open new connection on page initialization (handled by Header's componentDidMount(), temporary, need to move to timerService)
// + retrying when network down (handled by ReconnectingWebsocket)
// + skip connecting if a retrying is in progress or connection is establishing (handled by logic in WS_CONNECT)
// + random delay before retrying (exponential back-off) (handled by ReconnectingWebsocket)

// optional requirement:
// + make socketMiddleware not tampering with message content being sent/received
// + provide underlying ws connection for multiple services (eg. notification service, chat service)
// + handle multiple per-service connections (eg. notification service, chat service)

const socketMiddleware = () => {
  let socket = null;

  return store => next => (action) => {
    switch (action.type) {

      case 'WS_CONNECT':
        // if (socket !== null && (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)) {
        if (socket !== null) {
          // socket is connecting
          console.log('[WS] connected or connecting, skip', socket.readyState);
          return;
        }
        const url = action.host + "?sessionId=" + storage.getCookie('ted_signed_id');
        console.log('[WS] WS_CONNECT to ', url);
        // const maxRetries = 3;
        const options = {
          connectionTimeout: 30000,
          minReconnectionDelay: 30000,
          maxReconnectionDelay: 30000,
          // maxRetries: maxRetries, // default: Infinity
          debug: false,
          startClosed: false
        };
        // const options = {
        //   connectionTimeout: 30000,
        //   // maxRetries: maxRetries, // default: Infinity
        //   debug: false,
        //   startClosed: false
        // };
        // socket = new WebSocket(url);
        socket = new ReconnectingWebSocket(url, [], options);

        socket.addEventListener('open', () => {
          console.log('[WS] on open');
          if (action.onOpen) {
            store.dispatch({ type: action.onOpen });
          }
          if (socket !== null && socket.readyState === WebSocket.OPEN) {
            socket.send("{\"action\":\"default\"}");
          } else {
            console.log('[WS] socket connection is not ready');
          }
        });

        socket.addEventListener('message', (message) => {
          const data = JSON.parse(message.data);
          // DEBUG only, should NOT tamper with message content >>>>>
          const msg = data.map(e => {
            return e.transaction;
          })
          msg.sort(function (a, b) {
            if (a > b) {
              return 1;
            } else if (b > a) {
              return -1;
            }
            return 0;
          });
          console.log('[WS] on msg', msg);
          // DEBUG only <<<<<
          if (data.length === 0)
            storage.set("readAll", true)
          else
            storage.set("readAll", false)
          store.dispatch({ type: action.onMsg, data: { data: data } });
        });

        // socket.addEventListener('close', (e) => {
        //   console.log('[WS] close ', e);
        // });

        socket.addEventListener('error', (e) => {
          console.log('[WS] error', socket.retryCount);
          // if (socket.retryCount >= maxRetries) {
          // store.dispatch({ type: Action.ACTION_SHOW_ERROR, msg: 'Notifications are unavailable' });
          console.error("Notifications are unavailable")
          // socket.close();
          // socket = null;
          // }
        });
        break;
      case 'WS_DISCONNECT':
        console.log('[WS] disconnect ');
        if (socket !== null) {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send("{\"action\":\"disconnect\"}");
          }
          socket.close();
          socket = null;
        }
        break;
      case 'WS_SEND':
        if (socket !== null && socket.readyState === WebSocket.OPEN) {
          console.log('[WS] sending "', action.msg, '"');
          socket.send(action.msg);
        }
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
