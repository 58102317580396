import React from "react";
import Version from "./Version";

function Footer(props) {

  let footerClass = props.module && props.module === "login" ? "login-footer" : "footer"
  return (
    <>
      <div className="footer-holder">
        <div className={footerClass}>
          &copy;  {new Date().getFullYear()} Kintetsu World Express, Inc. All rights reserved. |{" "}
          <b> Privacy </b>
        </div>
        <Version {...props} />
      </div>
    </>
  );
}

export default Footer;
