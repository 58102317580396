// import { combineReducers } from 'redux';
// import header from './headerReducer';
// import config from './configReducer';
// import notification from './notificationReducer';
// // import notificationUI from './notificationUIReducer';
// import popup from './popupReducer';
// import confirm from './confirmReducer';
// import loading from './loadingReducer';
// import rollerLoading from './rollerLoadingReducer';

// import cbReducer from './cbReducer';
// import overviewReducer from './overviewReducer';
// import shippingInstruction from './siReducer';

// import overlay from './overlayReducer';
// import intialState from './initialState';
import { Action } from '../actions/ActionType';
import storage from '../common/services/storage';
import axios from "axios";
import store from '../store/store';

//import buildInfo from "../build.json";
// import React from "react";
// import ReactDOM from "react-dom";
// import VersionPopUp from "../components/Layout/VersionPopUp";
// const appReducer = combineReducers({
//   // short hand property names
//   header,
//   config,
//   loading,
//   rollerLoading,
//   notification,
//   // notificationUI,
//   popup,
//   confirm,
//   shippingInstruction,
//   cbReducer,
//   overviewReducer,
//   overlay
// })

export default function rootReducer(initialState, action) {
  if (action.type === Action.ACTION_USER_LOGOUT || action.type === Action.ACTION_USER_LOGOUT_AND_REDIRECT) {
    const messages = storage.get('messages');
    storage.flush(false);
    if (messages) {
      storage.set('messages', messages);
    }
  
   axios.get('/auth/logout/', { headers: { APP: 'TED' } })
    .then(res =>{ 
      if (res.data && res.data.success) {
        window.location.href = res.data.data ;
      }
    })
    .catch(err => {
      console.warn('sendRequestLogout -> err', err);
      store.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        msg: 'Server Error',
    });
  })
  }
  return {};
}

// export default rootReducer;