import { Action } from "../actions/ActionType";
import initialState from "./initialState";


export default function notificationReducer(
  state = initialState.notification, //NOSONAR
  action
) {
  const actionObject = {
    visible: true,
    msg: action.msg,
    msgIds: action.msgIds,
    msgId: action.msgId,
    msgParams: action.msgParams,
    callback: action.callback,
    onClose: action.onClose,
    // noCallbackOnCloseButton: action.noCallbackOnCloseButton,
    // actionButtonText: action.actionButtonText,
    // secondaryButtonText: action.secondaryButtonText,
    // secondaryButtonClickCallback: action.secondaryButtonClickCallback,
    buttons: action.buttons
  }
  // let data = action.data;
  switch (action.type) {
    case Action.ACTION_SHOW_ERROR:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Error",
        type: "ERROR"
      });
    case Action.ACTION_SHOW_WARN:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Warning",
        type: "WARNING"
      });
    case Action.ACTION_SHOW_INFO:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Info",
        type: "INFO"
      });
    case Action.ACTION_DIALOG_HIDE:
      return { visible: false };

    default:
      return state;
  }
}
