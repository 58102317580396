import { Action } from "../actions/ActionType";
import initialState from "./initialState";

export default function confirmReducer(
  state = initialState.confirm, //NOSONAR
  action
) {
  switch (action.type) {
    case Action.ACTION_SHOW_CONFIRM:
      return {
        visible: true,
        msg: action.msg,
        msgId: action.msgId,
        msgParams: action.msgParams,
        title: action.title ? action.title : "Confirmation",
        yes: action.yes,
        no: action.no,
        close: action.close
      };
    case Action.ACTION_HIDE_CONFIRM:
      return { visible: false };
    default:
      return state;
  }
}
